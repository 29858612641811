<template>
    <div>
      <eden-overview-card :title="title"></eden-overview-card>
      <eden-information-card>
        <template slot="content">
          <div
            class="is-flex is-flex-column gap-2"
          >
            <div>
              <p class="text-grey-tertiary mb-0">Pickup address</p>
              <p class="mb-0">{{ order?.vendor?.address }}</p>
            </div>
  
            <div>
              <p class="text-grey-tertiary mb-0">Phone Number</p>
              <p class="mb-0">{{ order?.recipient.customer_phone }}</p>
            </div>
          </div>
        </template>
      </eden-information-card>
    </div>
  </template>
  
  <script>
  export default {
    name: "OrderPickup",
    props: {
      data: {
        type: Object,
        default() {
          return [];
        },
      },
    },
    data() {
      return {};
    },
    computed: {
      title() {
        return `Pickup Information`;
      },
      order() {
        return this.data;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .information-card {
    padding: 18px 24px;
  }
  
  div {
    font-family: "CerebriSans", sans-serif;
  }
  
  p {
    font-size: 14px;
    margin-bottom: 24px;
  }
  </style>
  