<template>
  <div class="individual-vendor-details">
    <div v-if="loading">loading</div>
    <template v-else>
      <eden-page-header :title="'Orders & Schedule'" :subtitle="customerOrderNumber">
      </eden-page-header>
    </template>
    <template>
      <!-- <pre>{{ orderDetails }}</pre> -->
      <div class="order">
        <div class="is-flex is-flex-column">
          <eden-information-card>
            <template slot="content">
              <div class="order-summary">
                <div class="order-summary__image">
                  <img :src="getImage('meal.svg')" alt="service" />
                </div>
                <div class="order-summary__details">
                  <h6>#{{ orderDetails.order_number }}</h6>
                  <p class="text-bold">
                    {{ orderDetails?.recipient?.customer_name }}
                  </p>
                  <div class="is-flex is-justify-center mt-1 gap-1">
                    <p>
                      <el-tag :type="setType(orderDetails.status)">{{
                        formatText(orderDetails.status)
                      }}</el-tag>
                    </p>
                    <p>
                      <el-tag :type="setType(orderDetails.delivery_window)">
                        {{
                          orderDetails?.delivery_window === "on_demand"
                            ? "Instant Order"
                            : "Pre Order"
                        }}</el-tag>
                    </p>
                  </div>
                  <div class="is-flex is-justify-center my-2 gap-1">
                    <el-button v-if="allowAccessFor(['vendor_manager', 'admin'])" type="plain" size="medium"
                      @click="showOperationDetails = true" :disabled="isLogRefundDisabled">Log refund</el-button>
                    <el-button
                      v-if="allowAccessFor(['vendor_manager', 'admin']) && orderDetails.is_pickup_by_customer_order && orderDetails.status === 'Prepared'"
                      type="primary" size="medium" @click="markOrderAsPickedUp">Mark as Picked Up</el-button>
                  </div>
                </div>
              </div>
            </template>
          </eden-information-card>

          <eden-information-card title="General Info">
            <template slot="content">
              <div class="is-flex is-flex-column is-justify-between gap-1">
                <div class="border-bottom py-2 px-2">
                  <p class="general-header">Vendor Name</p>
                  <!-- <p class="font-base text-medium text-primary mt-1">
                    {{ formatName(orderDetails?.vendor_name) }}
                  </p> -->
                  <router-link :to="{
                    name: 'vendors.details',
                    params: { id: orderDetails.vendor_uuid },
                  }">
                    <span class="font-base text-medium text-primary mt-1">
                      {{ formatName(orderDetails.vendor_name) }}</span></router-link>
                </div>
                <div class="border-bottom py-2 px-2">
                  <p class="general-header">Account Manager</p>
                  <p class="font-base text-base mt-1">
                    {{ formatName(orderDetails?.account_manager_name) }}
                  </p>
                </div>
              </div>
            </template>
          </eden-information-card>

          <eden-information-card title="Refund Logged" v-if="orderDetails?.refund_details !== null">
            <template slot="content">
              <div class="is-flex is-flex-column is-justify-between gap-1">
                <div class="border-bottom py-2 px-2">
                  <p class="general-header">Items Refunded For</p>
                  <p class="font-base text-medium mt-1">
                    {{ orderDetails?.refund_details?.refund_quantity }} item(s)
                  </p>
                </div>
                <div class="border-bottom py-2 px-2">
                  <p class="general-header">Refund Logged By</p>
                  <p class="font-base text-base mt-1">
                    {{
                      formatName(orderDetails?.refund_details?.refund_by?.name)
                    }}
                  </p>
                </div>
              </div>
            </template>
          </eden-information-card>

          <eden-information-card title="Order Timeline">
            <template slot="content">
              <div class="is-flex is-flex-column is-justify-between gap-1">
                <div class="border-bottom py-2 px-2">
                  <p class="general-header">Order Placed</p>
                  <p class="font-base text-base">
                    {{ formatTime(orderDetails?.order_date, "12h") }}
                  </p>
                </div>
                <div class="border-bottom py-2 px-2" v-if="
                  orderDetails.status === 'Accepted' ||
                  orderDetails.status === 'Enroute pickup' ||
                  orderDetails.status === 'Dispatched' ||
                  orderDetails.status === 'Completed'
                ">
                  <p class="general-header">Order Accepted</p>
                  <p class="font-base text-base">
                    {{ formatTime(orderDetails?.accepted_at, "12h") }}
                  </p>
                </div>
                <div class="border-bottom py-2 px-2" v-if="
                  orderDetails.status === 'Dispatched' ||
                  orderDetails.status === 'Completed'
                ">
                  <p class="general-header">Order Picked Up</p>
                  <p class="font-base text-base">
                    {{ formatTime(orderDetails?.dispatched_at, "12h") }}
                  </p>
                </div>
                <div class="border-bottom py-2 px-2" v-if="orderDetails.status === 'Completed'">
                  <p class="general-header">Order Delivered</p>
                  <p class="font-base text-base">
                    {{ formatTime(orderDetails?.completed_at, "12h") }}
                  </p>
                </div>
              </div>
            </template>
          </eden-information-card>
        </div>
        <div class="order-body">
          <div class="detail">
            <el-tabs v-model="tab" @tab-click="updateRouteQuery">
              <el-tab-pane label="Order" name="order">
                <OrderItems :data="orderDetails" />
                <OrderDelivery :data="orderDetails" v-if="
                  orderDetails.status !== 'Pending' &&
                  orderDetails.status !== 'Rejected' &&
                  !orderDetails.is_pickup_by_customer_order
                " />
                <OrderPickup :data="orderDetails" v-if="
                  orderDetails.status !== 'Pending' &&
                  orderDetails.status !== 'Rejected' &&
                  orderDetails.is_pickup_by_customer_order
                " />
                <OrderRecipient :data="orderDetails" />
                <div v-if="orderDetails.status === 'Cancelled'">
                  <eden-overview-card title="Reason for cancellation"></eden-overview-card>
                  <eden-information-card class="cancellation-card">
                    <template slot="content">
                      <div class="">
                        {{ orderDetails.reason_for_cancellation }}
                      </div>
                    </template>
                  </eden-information-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </template>
    <LogRefund :show.sync="showOperationDetails" :data="orderDetails" @success="getOrderDetails" />
  </div>
</template>

<script>
import marketplace from "@/requests/orders/marketplace.js";
import OrderItems from "../../components/OrdersAndSchedule/OrderItems.vue";
import OrderDelivery from "../../components/OrdersAndSchedule/OrderDelivery.vue";
import OrderPickup from "../../components/OrdersAndSchedule/OrderPickup.vue";
import OrderRecipient from "../../components/OrdersAndSchedule/OrderRecipient.vue";
import LogRefund from "../../components/Vendors/details/LogRefund.vue";

export default {
  components: {
    OrderItems,
    OrderDelivery,
    OrderPickup,
    OrderRecipient,
    LogRefund,
  },
  data() {
    return {
      loading: false,
      showOperationDetails: false,
      orderDetails: {},
      tab: "order",
    };
  },
  created() {
    this.getOrderDetails();
  },
  computed: {
    customerOrderNumber() {
      return `Order #${this.orderDetails?.order_number}`;
    },
    isLogRefundDisabled() {
      return this.orderDetails.items?.some((item) => item.refund_logs !== null);
    },
  },
  methods: {
    getOrderDetails() {
      this.loading = true;
      marketplace
        .index(this.$route.params.id)
        .then((response) => {
          if (response.data.status) {
            this.orderDetails = response.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data);
          this.loading = false;
        });
    },
    markOrderAsPickedUp() {
      this.loading = true;
      const payload = {
        uuid: this.orderDetails.uuid,
      };
      marketplace
        .mark(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.getOrderDetails();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data);
          this.loading = false;
        });
    },
    updateRouteQuery(tab) {
      const { t } = this.$route.query;

      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "ordersAndSchedule.details",
        params: { id: this.orderDetails.uuid },
        query: { t: tab.name },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 320px;

.individual-vendor-details {
  .body {
    margin-top: 45px;

    .detail {
      margin-left: 30px;
      width: calc(100% - #{$-summary-width});
    }
  }

  @media (max-width: 992px) {
    .body {
      flex-wrap: wrap;

      .detail {
        margin: 0;
        width: 100%;
      }
    }
  }
}

.order {
  display: flex;

  .order-summary {
    color: #0f241b;

    &__image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;
      background-color: #d4f7e5;

      &.beauty {
        background-color: var(--eden-pink-senary);
      }

      &.laundry {
        background-color: var(--eden-purple-septenary);
      }

      &.cleaning {
        background-color: var(--eden-orange-septenary);
      }

      &.dailymeal,
      &.meal {
        background-color: var(--eden-green-senary);
      }

      img {
        width: 38px;
        height: 38px;
      }
    }

    &__details {
      text-align: center;
      margin-bottom: 16px;

      h6 {
        font-family: "CerebriSans", sans-serif;
        font-size: 1.125rem;
        margin-bottom: 7px;
        color: var(--eden-green-primary);
      }
    }

    &__tags {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }

    &__schedule {
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &-body {
    $-summary-width: 400px;
    margin-left: 64px;
    width: calc(100% - #{$-summary-width});
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;

    &-body {
      margin-left: 0;
      width: 100%;
    }
  }
}

.cancellation-card {
  padding: 18px 24px;
}

.information-card {
  height: max-content;
}

.border-bottom {
  border-bottom: 1px solid #f0f4f2;
}

.general-header {
  color: var(--eden-grey-quaternary);
}
</style>
